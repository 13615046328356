//Common stuff that is mandatory on every main js
import { initInputNumber } from "./input-number";
import SwitchHost from "../management/SwitchHost";
// import { initBlackFridayBannerClose } from "./init-black-friday-banner-close";
// import { initValentineDayBannerClose } from "./init-valentine-day-banner-close";
import { initOffuscation } from "../frontv2/link-offuscation";
import { ajax, getUserLanguage, isMobile } from "../utils";
import Message from "../Message";
import { initExploreMenu } from "../frontv2/HeaderHome";
import { closeRemindOnboardingNavbar } from "../frontv2/CloseRemindOnbardingNavbar";
import AuthenticationForm from "../frontv2/AuthenticationForm";
// import { initLuckyOrange } from "./lucky-orange";
import { initBtnLoading } from "../frontv2/btn-loading";
import Dob from "./Dob";
import removeFlashMessage from "../frontv2/FlashMessage";
import Drawer from "./Drawer";
import { initMelchiorInput } from "./init-melchior-input";
import { initTabs } from "./initTabs";
import { bindLanguageAndCurrencyBtn } from "../frontv2/bindLanguageAndCurrencyBtn";
import { initNav } from "./nav";
import { initBehaviorForDropdownRecentlyViewed } from "../frontv2/behaviorRecentlyViewed";
import { initExcellenceAwardSpinVisibility } from "./init-excellence-award-visibility";
// import CookiesConsent from "./init-cookies-consent";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { initGiftCardTopBannerCollapse } from "../frontv2/Home/gift-card-banner-collapse";
import { initReadMoreExtraDescription } from "../frontv2/Extra/initReadMoreExtraDescription";

//Common stuff
const initCommon = () => {
  // init drawers
  Drawer.init();

  // Init nav bar
  initNav();

  // Behavior for dropdown recently viewed
  initBehaviorForDropdownRecentlyViewed();

  //init tippy
  tippy('[data-tippy-content]', {
    theme: 'light',
    onShow: (instance) => {
      if(instance.reference.classList.contains('prevent-tippy')) return false;
    }
  });

  //cookies logic
  // new CookiesConsent();

  //all logic for signup/login pages/modals
  new AuthenticationForm();

  //Facebook login
  const appId = document.body.dataset.env == "prod" ? '432852340251825' : '387710185153647';

  window.fbAsyncInit = function() {
    FB.init({
      appId      : appId,
      cookie     : true,                     // Enable cookies to allow the server to access the session.
      xfbml      : true,                     // Parse social plugins on this webpage.
      version    : 'v11.0'           // Use this Graph API version for this call.
    });
  };

  const fbButtons = document.querySelectorAll('.fb-login');
  if(fbButtons){
    const loginViaFacebook = (fbButton) => {
      FB.getLoginStatus(function(response) {
          if (response.status === 'connected') {
            //Checks if user exists or create it and log
            FB.api('/me?fields=id,email,first_name,last_name', function(response) {
              if(typeof response.id !== 'undefined'){
                const {id, email, first_name, last_name} = response;

                const formData = new FormData();
                formData.append('identifier', id);
                formData.append('email', email);
                formData.append('first_name', first_name);
                formData.append('last_name', last_name);
                formData.append('provider', 'facebook');

                ajax(fbButton.dataset.fbLoginUrl, function(data){
                  const {success, message, redirectUrl} = data;

                  if(success){
                    window.location.replace(redirectUrl);
                  }
                  else{
                    // loginViaFacebook(fbButton);
                    Message.error(message);
                  }
                }, 'POST', formData,
                { "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content }
                , true, true);
              } else {
                FB.login(function(response) {
                  loginViaFacebook(fbButton);
                }, {scope: 'public_profile,email'});
              }
            });
          } else {
            //Asks user to log into facebook to be able to log in winalist
            FB.login(function(response) {
              loginViaFacebook(fbButton);
            }, {scope: 'public_profile,email'});
          }
      });
    }

    fbButtons.forEach((fbButton) => {
      fbButton.addEventListener('click', () => {
        loginViaFacebook(fbButton);
      });
    });
  }

  if(typeof window.AppleID !== "undefined"){
    //Apple login
    window.AppleID.auth.init({
      clientId : 'com.winalist.signinapple',
      scope : 'name email',
      redirectURI : 'https://'+window.location.hostname,
      state : document.querySelector('meta[name="csrfToken"]')?.content,
      usePopup : true //or false defaults to false
    });

    document.addEventListener('AppleIDSignInOnSuccess', (data) => {
      const appleSignInButton = document.getElementById('appleid-signin');
      const detail = data.detail;

      if(appleSignInButton && detail){
          const {authorization, user} = detail;

          const parseJwt = (token) => {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
          };

          const parsedData = parseJwt(authorization.id_token);
          const {sub, email} = parsedData;
          const formData = new FormData();

          formData.append('identifier', sub);
          formData.append('email', email);
          formData.append('provider', 'apple');

          if(user){
            formData.append('first_name', user.name.firstName);
            formData.append('last_name', user.name.lastName);
          }

          ajax(appleSignInButton.dataset.externalLoginUrl, function(data){
            const {success, message, redirectUrl} = data;

            if(success){
              window.location.replace(redirectUrl);
            } else {
              Message.error(message);
            }
          }, 'POST', formData,
          { "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content }
          , true, true);
      }
    });
  }

  document.querySelectorAll('.dob').forEach((input) => {
    const form = input.closest('form');
    if(form){
      form.addEventListener('submit', (event) => {
        if(event.submitter && event.submitter.nodeName === 'BUTTON' && !event.submitter.hasAttribute('type')){
          event.preventDefault();
          return false;
        }
      }, false);
    }

    const months = JSON.parse(input.dataset.months);

    const formattedDob = document.getElementById('formattedDob');
    if(formattedDob){
      formattedDob.addEventListener('click', () => { input.focus(); });
    }


    dobDatepicker(input, {
      dateFormat: function(date){
        const formattedDob = document.getElementById('formattedDob');

        if(formattedDob){
          var d = new Date(date);
          function pad(s) { return (s < 10) ? '0' + s : s; }
          let value = date;

          if(getUserLanguage().includes("en")) {
            value = [ pad(d.getMonth()+1), pad(d.getDate()), d.getFullYear()].join('/');
          } else {
            value = [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/');
          }


          formattedDob.value = value;
        }

        return date;
      },
      long_month: months,
      labels: {
        header_label:  input.dataset.headerLabel,
        select_day_label:  input.dataset.selectDayLabel,
        select_month_label:  input.dataset.selectMonthLabel,
        select_year_label:  input.dataset.selectYearLabel,
        reset_button_label:  input.dataset.resetButtonLabel,
        date_range_label:  input.dataset.dateRangeLabel + ' '  //label for year section -> "Year 2000 - 2020"
      },
      display_mode: isMobile() ? 'popup' : 'inline',
      enable_ordinal_number: false
    });

    // window.addEventListener('click', (e) => {
    //   const dobButtonClasses = [
    //     'dob-datepicker__day__ordinal-number',
    //     'dob-datepicker__year__button',
    //     'dob-datepicker__day__button',
    //     'dob-datepicker__year__button__last',
    //     'dob-datepicker__year__button__first',
    //     'dob-datepicker__year__button',
    //   ];

    //   const isDobButtonClick = (target) => {
    //     let res = false;
    //     dobButtonClasses.forEach((word) => {
    //       if(target.classList.contains(word)) res = true;
    //     });

    //     return res;
    //   }

    //   if(isDobButtonClick(e.target)) e.target.type = 'button';
    // });
  })


  //Input number logic
  initInputNumber();

  if(document.body.dataset.connected === 'true'){
    new SwitchHost();
  }

  let mobileNav = document.getElementById("mobile-fullscreen-nav");
  let navBarToggle = document.getElementById("js-navbar-toggle");
  if(navBarToggle && mobileNav){
    navBarToggle.addEventListener("click", function() {
      mobileNav.classList.toggle("active");
    });

    const closeMobileNav = mobileNav.querySelector('.close');
    if(closeMobileNav){
      closeMobileNav.addEventListener('click', function(){
        mobileNav.classList.remove("active");
      });
    }
  }

  const showMoreButtons = document.querySelectorAll(".show-more-links");
  if(showMoreButtons){
    showMoreButtons.forEach(function(button){
      button.addEventListener('click', function(){
        const links = this.parentNode.querySelectorAll('.link');
        if(links){
          links.forEach(function(link){
            link.classList.remove('hidden');
          });
        }

        this.remove();
      });
    });
  }

  //Logic for generating affiliation link for partners users
  if(document.body.dataset.connected === 'true'){
    const generateLink = document.getElementById('generate-link');
    const generatedLinkContainer = document.getElementById('generated-link');
    const copyButton = document.getElementById('copy-generated-link');
    if(generateLink && generatedLinkContainer && copyButton){
      const specificUrl = generateLink.hasAttribute('data-specific-url') ? generateLink.dataset.specificUrl : '';

      generateLink.addEventListener('click', function(){
        //First remove all get params from the link
        let link = specificUrl != '' ? specificUrl : window.location.href;
        if(link.indexOf('?') !== -1){
          link = link.substring(0, link.indexOf('?'));
        }

        //Then add the affiliate params to it
        link += '?utm_source=' + generateLink.dataset.source + '&utm_medium=' + generateLink.dataset.medium;
        if(generateLink.hasAttribute('data-campaign')){
          link += '&utm_campaign=' + generateLink.dataset.campaign;
        }
        generatedLinkContainer.value = link;

        setTimeout(function(){
          /* Select the text field */
          generatedLinkContainer.select();
          generatedLinkContainer.setSelectionRange(0, 99999); /*For mobile devices*/

          /* Copy the text inside the text field */
          document.execCommand("copy");
        }, 300);
      });

      copyButton.addEventListener('click', function(){
        /* Select the text field */
        generatedLinkContainer.select();
        generatedLinkContainer.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");

        copyButton.textContent = copyButton.dataset.copied;
      });
    }

    const socialButtons = document.querySelectorAll('.social-button');
    if(socialButtons && generatedLinkContainer){
      socialButtons.forEach(button => {
        button.addEventListener('click', function(){
          //First remove all get params from the link
          let link = window.location.href;
          if(link.indexOf('?') !== -1){
            link = link.substring(0, link.indexOf('?'));
          }

          //Then add the affiliate params to it
          link += '?utm_source=' + button.dataset.source + '&utm_medium=social';
          generatedLinkContainer.value = link;

          setTimeout(function(){
            /* Select the text field */
            generatedLinkContainer.select();
            generatedLinkContainer.setSelectionRange(0, 99999); /*For mobile devices*/

            /* Copy the text inside the text field */
            document.execCommand("copy");
          }, 300);
        });
      });
    }
  }

  //Hide links for bot crawlers
  initOffuscation();

  //show that it is loading by displaying an circle icon into the button
  initBtnLoading();

  //Init explore menu
  initExploreMenu();

  //Logic to close remind onboarding navbar
  closeRemindOnboardingNavbar();

  //init lucky orange tracking
  // initLuckyOrange();

  //init custom DOB logic
  new Dob();

  // Remove flash message after 4 seconds
  removeFlashMessage();

  //melchior input text
  initMelchiorInput();

  //tab system logic
  initTabs();

  // On click on language or currency button => change the tab
  bindLanguageAndCurrencyBtn();

  initExcellenceAwardSpinVisibility();

  //init logic for the gift card top banner collapse when scrolling
  initGiftCardTopBannerCollapse();

  //init logic for the read more button in the extra description
  initReadMoreExtraDescription();
}

export { initCommon }
